@use "sass:math";

@use '@angular/material' as mat;
@use '../utils/variables' as ftVars;

@mixin apply-nav-bar-theme() {
  border: none;
  background-color: rgb(255, 255, 255);
  border-radius: ftVars.$ft-border-radius ftVars.$ft-border-radius 0 0;
  border-bottom: 3px solid mat.get-color-from-palette(ftVars.$ft-accent-palette);

  &.with-toolbar {
    border-bottom: none;

    .mat-tab-links {
      height: ftVars.$module-toolbar-height - ftVars.$ft-medium-spacer;;
    }
  }

  .mat-tab-links {
    height: ftVars.$module-toolbar-height - 3px;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-link-container .mat-tab-link, .mat-tab-link-container .mat-button {
    opacity: 1;
    height: inherit;
    padding: 0 ftVars.$ft-large-spacer;

    border-radius: 0;
    min-width: unset;
    background-color: rgb(255, 255, 255);
    color: mat.get-color-from-palette(ftVars.$ft-accent-palette, 400);
    box-shadow: 0 1px 0 1px mat.get-color-from-palette(ftVars.$ft-accent-palette, 50);

    transition: .3s all cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      color: mat.get-color-from-palette(ftVars.$ft-primary-palette, 500);
    }

    &.mat-tab-label-active {
      box-shadow: 0 0 0 0;
      color: rgb(255, 255, 255);
      background-color: mat.get-color-from-palette(ftVars.$ft-accent-palette);
    }

    &.mat-tab-disabled, &[disabled] {
      color: mat.get-color-from-palette(ftVars.$ft-accent-palette, 400, .5);
    }

    &.with-icon {
      padding: 0 ftVars.$ft-large-spacer 0 math.div(ftVars.$ft-large-spacer, 3) ;

      & > .mat-icon, .mat-button-wrapper .mat-icon {
        margin-right: ftVars.$ft-small-spacer;
      }
    }
  }
}

// dark them overrides
.ft-dark-theme .ft-parent-layout nav.mat-tab-nav-bar {

}
