@use "sass:map";

@use "@angular/material" as mat;
@use "variables" as ftVars;
@use "mixins" as ftMixins;

$ft-custom-typography: mat.define-typography-config(
  $font-family: "Inter, sans-serif",
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(45px, 48px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(13px, 24px, 400),
  $body-2: mat.define-typography-level(13px, 24px, 500),
  $body-1: mat.define-typography-level(13px, 20px, 400),
  $caption: mat.define-typography-level(ftMixins.rem(1.2), 20px, 400),
  $button: mat.define-typography-level(ftMixins.rem(1.4), 500, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(ftMixins.rem(1.4), 1.125, 500),
);

$ft-main-theme: mat.define-light-theme(
  (
    color: (
      primary: ftVars.$ft-primary-palette,
      accent: ftVars.$ft-accent-palette,
      warn: ftVars.$ft-warn-palette,
    ),
    typography: $ft-custom-typography,
  )
);

$ft-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: ftVars.$ft-primary-palette,
      accent: ftVars.$ft-accent-palette,
      warn: ftVars.$ft-warn-palette,
    ),
  )
);

// colors (background and foregrounds)
$dark-config: mat.get-color-config($ft-dark-theme);
$white-config: mat.get-color-config($ft-main-theme);

$dark-foreground: map.get($dark-config, foreground);
$dark-background: map.get($dark-config, background);
$white-foreground: map.get($white-config, foreground);
$white-background: map.get($white-config, background);

@mixin apply-core-theme {
  @include mat.core();
  @include mat.all-component-themes($ft-main-theme);

  // dark theme (needs some work)
  .ft-dark-theme {
    @include mat.all-component-colors($ft-dark-theme);
  }
}

// a {
//         font-family: "Roboto";
//         font-style: normal;
//         text-decoration: none; /* no underline */
//       }

.base-btn {
  cursor: pointer;
  border: 1px solid transparent;
  background-color: transparent;
}

.pr-btn {
  width: 35px;
  height: 35px;
  cursor: pointer;
  outline: 0;
  color: #92939a;
  // background-color: rgba(146, 147, 154, 0.1);
  // border-color: rgba(146, 147, 154, 0.1);
  background-color: #92939a1a;
  border-color: #92939a1a;
  // display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  border: 1px solid transparent;
  padding: 0;
  margin: 5px 0;
  font-size: 12px;
  // border-radius: 0.25rem;
  border-radius: 35px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: #000;
    background-color: #dddddd;
    border-color: #dddddd;
  }

  &:disabled {
    cursor: auto;
    color: #b1b1b1;
    background-color: #f9f9f9;
    border-color: #ececec;

    &:hover {
      color: #b1b1b1;
      background-color: #f9f9f9;
      border-color: #ececec;
    }
  }
}

.icon-text-btn {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        height: 38px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        background: #4177fc;
        color: #fff;
      
        .icon-part {
          width: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .text-part {
          flex-grow: 1;
          display: flex;
          align-items: center;
        }
      }
