@use "sass:math";

@use '@angular/material' as mat;
@use '@angular/material/theming' as matTheming;

$pr-electric-blue-palette: (
    50 : #e8efff,
    100 : #c6d6fe,
    200 : #a0bbfe,
    300 : #7aa0fd,
    400 : #5e8bfc,
    500 : #4177fc,
    600 : #3b6ffc,
    700 : #3264fb,
    800 : #2a5afb,
    900 : #1c47fa,
    A100 : #ffffff,
    A200 : #fbfbff,
    A400 : #c8d1ff,
    A700 : #aebcff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$pr-turquoise-palette: (
    50 : #e0f5f6,
    100 : #b3e6e9,
    200 : #80d6da,
    300 : #4dc6cb,
    400 : #26b9c0,
    500 : #00adb5,
    600 : #00a6ae,
    700 : #009ca5,
    800 : #00939d,
    900 : #00838d,
    A100 : #b9f9ff,
    A200 : #86f5ff,
    A400 : #53f0ff,
    A700 : #3aeeff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$pr-coral-sunset-palette: (
    50 : #fef0ed,
    100 : #fcd9d3,
    200 : #fbbfb6,
    300 : #f9a598,
    400 : #f79282,
    500 : #f67f6c,
    600 : #f57764,
    700 : #f36c59,
    800 : #f2624f,
    900 : #ef4f3d,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffdad6,
    A700 : #ffc3bd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$pr-softer-peachy-orange-palette: (
    50 : #fff4ef,
    100 : #ffe3d7,
    200 : #ffd0bd,
    300 : #ffbda2,
    400 : #ffae8e,
    500 : #ffa07a,
    600 : #ff9872,
    700 : #ff8e67,
    800 : #ff845d,
    900 : #ff734a,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff4f1,
    A700 : #ffe0d7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$pr-steel-blue-palette: (
    50 : #cecfd4,
    100 : #c8cacf,
    200 : #a3a7af,
    300 : #7e838f,
    400 : #626977,
    500 : #464e5f,
    600 : #3f4757,
    700 : #373d4d,
    800 : #2f3543,
    900 : #202532,
    A100 : #d3d5d9,
    A200 : #d9dadd,
    A300 : #dedfe2,
    A400 : #e4e5e7,
    A500 : #e9eaec,
    A600 : #efeff1,
    A700 : #f4f4f5,
    A800 : #fafafa,
    A900 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

//size
$mat-icon-size: 20px !default;
$mat-svg-size: math.div($mat-icon-size, 1px) !default;

// media dependencies
$min-lim-lg-top: 1600px !default;
$min-lim-lg: 1440px !default;
$min-lim-md: 1280px !default;
$min-lim-sm: 960px !default;

//spacer
$ft-small-spacer: 6px !default;
$pr-small-spacer: 6px !default;
$ft-medium-spacer: 8px !default;
$ft-large-spacer: 12px !default;
$pr-large-spacer: 16px !default;
$ft-extra-small-spacer: $ft-small-spacer * 0.5 !default;

$ft-border-radius: $ft-medium-spacer * 0.5 !default;

//toolbar
$toolbar-small-spacer: $ft-small-spacer*2 !default;
$toolbar-large-spacer: $ft-large-spacer*2 !default;
$toolbar-height: 60px;

//dimension
$toolbar-small-height: 40px !default;
$toolbar-large-height: 60px !default;

$toolbar-side-nav-height: 28px !default;

$module-toolbar-height: 35px !default;
$module-nav-height: 40px !default;
$module-actions-height: 48px !default;
$module-action-buttons-height: 30px !default;
$module-action-bar-buttons-height: 26px !default;

$ft-select-height: 30px !default;
$ft-global-drawer-width: 250px !default;

// table
$table-line-dimension: 30px !default;
$table-search-bar-dimension: 35px !default;

//material palette
$is-dark-theme: false !default;
// $ft-primary-palette: mat.define-palette(mat.$light-blue-palette, 500) !default;
// $ft-primary-palette: mat.define-palette($pr-turquoise-palette, 500) !default;
// $ft-primary-palette: mat.define-palette(mat.$pink-palette, 500) !default;
$ft-primary-palette: mat.define-palette($pr-electric-blue-palette, 500) !default;
$ft-accent-palette: mat.define-palette($pr-steel-blue-palette, 500) !default;
// $ft-accent-palette: mat.define-palette(mat.$blue-grey-palette, 500) !default;
// $ft-accent-palette: mat.define-palette($pr-softer-peachy-orange-palette, 900) !default;
$ft-warn-palette: mat.define-palette(mat.$red-palette, 600) !default;
// $ft-warn-palette: mat.define-palette($pr-coral-sunset-palette, 500) !default;
$ft-success-palette: mat.define-palette(mat.$green-palette, 500) !default;
$ft-warning-palette: mat.define-palette(mat.$yellow-palette, A200) !default;
$ft-grey-palette: mat.define-palette(mat.$blue-grey-palette, 50) !default;

//colors
$ft-primary-color: mat.get-color-from-palette($ft-primary-palette, default) !default;
$ft-primary-darker-color: mat.get-color-from-palette($ft-primary-palette, 800) !default;
$ft-accent-color: mat.get-color-from-palette($ft-accent-palette, default) !default;
$ft-warn-color: mat.get-color-from-palette($ft-warn-palette, default) !default;
$ft-success-color: mat.get-color-from-palette($ft-success-palette, default) !default;
$ft-warning-color: mat.get-color-from-palette($ft-warning-palette, default) !default;
$ft-grey-color: mat.get-color-from-palette($ft-grey-palette, default) !default;

$ft-light-border-color: mat.get-color-from-palette($ft-grey-palette, default) !default;
$ft-medium-border-color: mat.get-color-from-palette($ft-grey-palette, 100) !default;

$ft-grey-border-color: mat.get-color-from-palette(mat.$grey-palette, 100) !default;
$ft-action-background-color: mat.get-color-from-palette(mat.$grey-palette, 50) !default;

$body-background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 50) !default;

// material
$mat-toggle-size: 18px !default;
$mat-radio-ripple-radius: 20px !default;

// animation
$ft-animation-function: matTheming.$swift-ease-in-out-timing-function;



/***********************************/
//spacer
$pr-standard-spacer: 1rem;
$pr-scrollbar-width: 0.25rem;

//radius
$pr-standard-border-radius: 6px;
$pr-circular-element-border-radius: 50%;

//color
$ft-primary-color-50: mat.get-color-from-palette($ft-primary-palette, 50) !default;
$ft-primary-color-100: mat.get-color-from-palette($ft-primary-palette, 100) !default;
$ft-primary-color-200: mat.get-color-from-palette($ft-primary-palette, 200) !default;
$ft-primary-color-300: mat.get-color-from-palette($ft-primary-palette, 300) !default;
$pr-background-primary: #fff;
$pr-background-secondary: #D9D9D9;
$pr-background-hover: #f3f3f3;
// $pr-background-mobile: linear-gradient(180deg, #4177FC 0%, #003CCF 100%);
$pr-background-mobile: linear-gradient(180deg, #f1f5ff 0%, white 100%);
$pr-background-desktop-gradient: linear-gradient(180deg, #f1f5ff 0%, white 100%);
$pr-background-settings: #F5F5F5;
$pr-background-desktop: linear-gradient(180deg, #f1f5ff 0%, white 100%);
$pr-foreground-primary: #000;
$pr-foreground-secondary: mat.get-color-from-palette($ft-accent-palette, 200) !default;
$pr-accent-a900: mat.get-color-from-palette($ft-accent-palette, A900) !default;
$pr-accent-a800: mat.get-color-from-palette($ft-accent-palette, A800) !default;
$pr-accent-a700: mat.get-color-from-palette($ft-accent-palette, A700) !default;
$pr-accent-a600: mat.get-color-from-palette($ft-accent-palette, A600) !default;
$pr-accent-a500: mat.get-color-from-palette($ft-accent-palette, A500) !default;
$pr-accent-a400: mat.get-color-from-palette($ft-accent-palette, A400) !default;
$pr-accent-a300: mat.get-color-from-palette($ft-accent-palette, A300) !default;
$pr-accent-a200: mat.get-color-from-palette($ft-accent-palette, A200) !default;
$pr-accent-a100: mat.get-color-from-palette($ft-accent-palette, A100) !default;
$pr-accent-50: mat.get-color-from-palette($ft-accent-palette, 50) !default;
$pr-accent-100: mat.get-color-from-palette($ft-accent-palette, 100) !default;
$pr-accent-300: mat.get-color-from-palette($ft-accent-palette, 300) !default;
$pr-accent-400: mat.get-color-from-palette($ft-accent-palette, 400) !default;
$pr-accent-700: mat.get-color-from-palette($ft-accent-palette, 700) !default;
$pr-accent: $ft-accent-color;
$pr-divider-color: #EDEDED;
$pr-divider-color2: #DEDEDE;

// font
$pr-font-secondary : 0.8125rem;
$pr-font-primary : 0.875rem;
$pr-font-subtitle3 : 0.9375rem;
$pr-font-subtitle2 : 1rem;
$pr-font-subtitle1 : 1.125rem;
$pr-font-h6 : 1.25rem;
$pr-font-h5 : 1.4375rem;
$pr-font-h4 : 1.625rem;
$pr-font-h3 : 1.8125rem;
$pr-font-h2 : 2rem;
$pr-font-h1 : 2.25rem;

//shadow
// $pr-shadow-mobile: 0px 18px 27px 0px rgba(0, 0, 0, 0.2);
$pr-shadow-mobile: 0px 18px 27px rgba(0.96, 92.8, 230.56, 0.13);
$pr-shadow-mobile2: 0px 18px 27px rgba(0.96, 92.8, 230.56, 0.13);
$pr-shadow-settings: 0px 1px 2px 0px rgba(0, 0, 0, 0.23);
$pr-shadow-desktop: 0px 18px 27px rgba(0.96, 92.8, 230.56, 0.13);

// css variable definitions
@mixin load-root-variables() {
  :root {
    // color part
    --ft-primary-color: #{$ft-primary-color};
    --ft-primary-darker-color: #{$ft-primary-darker-color};
    --ft-accent-color: #{$ft-accent-color};
    --ft-warn-color: #{$ft-warn-color};
    --ft-success-color: #{$ft-success-color};
    --ft-warning-color: #{$ft-warning-color};
    --ft-grey-color: #{$ft-grey-color};

    --ft-grey-border-color: #{$ft-grey-border-color};
    --ft-light-border-color: #{$ft-light-border-color};
    --ft-medium-border-color: #{$ft-medium-border-color};
    --ft-action-background-color: #{$ft-action-background-color};
  }
}
