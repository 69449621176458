@use '@angular/material/theming' as matTheming;
@use "../utils/variables" as prVars ;
@use "../utils/mixins" as prMixins ;
@use "~mq-scss/mq" as mq;

.mat-list-base .mat-list-item {
  &.ft-list-item-disabled {
    cursor: default;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.5);
  }

  &.ft-list-item-active:not(.ft-list-item-disabled) {
    background-color: rgba(prVars.$ft-primary-color, .1);

    .mat-list-text .mat-line, .mat-list-icon {
      color: prVars.$ft-primary-color;
    }
  }
}

@mixin base-history-theming {
  .button-action-bar {
    padding-bottom: prVars.$ft-small-spacer;
    border-bottom: 2px solid prVars.$ft-light-border-color;
    margin: prVars.$ft-small-spacer prVars.$ft-small-spacer 0 prVars.$ft-small-spacer;

    .mat-button {
      margin: 0;
      padding: 0 prVars.$ft-small-spacer;
      height: prVars.$module-action-bar-buttons-height;
      min-width: prVars.$module-action-bar-buttons-height;
      line-height: prVars.$module-action-bar-buttons-height;

      border-radius: 0;
      color: matTheming.$light-primary-text;
      background: prVars.$ft-primary-color;
      box-shadow: 0 3px 0px -6px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

      &::ng-deep .mat-button-ripple-round {
        border-radius: inherit;
      }

      &:first-of-type {
        border-radius: prVars.$ft-border-radius 0 0 prVars.$ft-border-radius;
      }

      &:last-of-type {
        border-radius: 0 prVars.$ft-border-radius prVars.$ft-border-radius 0;
      }

      &.icon-only {
        padding: 0;
      }
    }
  }

  .ft-content-container {
    background-color: inherit;

    .mat-drawer {
      @include prMixins.scrollbars(.3em, $track: false);

      padding: prVars.$ft-small-spacer;
      border-right: 2px prVars.$ft-light-border-color solid;

      width: 220px;
      min-width: 220px;

      @include mq.mq(max, prVars.$min-lim-md) {
        width: 195px;
        min-width: 195px;
      }

      @include mq.mq(inside, prVars.$min-lim-lg, prVars.$min-lim-md) {
        width: 210px;
        min-width: 210px;
      }

      .mat-drawer-inner-container {
        display: flex;
        flex-direction: column;
      }
    }

    .mat-toolbar {
      @include prMixins.ft-toolbar($padding: calc(prVars.$ft-small-spacer/2), $height: prVars.$toolbar-side-nav-height);

      .ft-module-icon > .mat-icon {
        width: prVars.$toolbar-side-nav-height;
        height: prVars.$toolbar-side-nav-height;
        line-height: prVars.$toolbar-side-nav-height;
      }

      h2 {
        font-size: 16px;
        line-height: prVars.$toolbar-side-nav-height + 2px;
      }

      > .mat-icon-button .mat-icon {
        font-size: prVars.$toolbar-side-nav-height - prVars.$ft-medium-spacer;
      }

    }

    .mat-drawer-inner-container .mat-toolbar {
      background-color: prVars.$ft-primary-color;
      color:white ;
    }

    .mat-list-base {
      flex: 1 1 100%;
      padding-top: 0;
      border: 1px solid prVars.$ft-light-border-color;
      border-top: none;
      border-radius: 0 0 prVars.$ft-border-radius prVars.$ft-border-radius;

      .mat-subheader {
        font-size: 14px;
        overflow: hidden;
        position: relative;
        padding: calc(prVars.$ft-small-spacer/2);
        height: prVars.$toolbar-side-nav-height;
        background-color: matTheming.mat-color(prVars.$ft-accent-palette, 50);

        &:first-child {
          margin-top: unset;
        }

        > .mat-button {
          padding: 0;
          width: 100%;
          height: 100%;
          z-index: 100;
          min-width: unset;
          border-radius: 0;
          position: absolute;
          margin: 0 0 0 calc(prVars.$ft-small-spacer/2 * -1);
        }
      }

      .mat-list-item {
        height: prVars.$toolbar-side-nav-height;
        border-bottom: 1px prVars.$ft-grey-border-color solid;
        transition: .3s all cubic-bezier(0.4, 0, 0.2, 1);

        .mat-line {
          cursor: pointer;
          text-align: left;
          align-items: center;

          .mat-icon {
            max-width: 24px;

            &:first-of-type {
              margin-right: prVars.$ft-small-spacer;
            }
          }

          span {
            font-size: 13px;
            font-weight: 500;
            margin-right: calc(prVars.$ft-small-spacer/2);

            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .mat-list-item-content {
          padding: 0;

          .mat-button {
            padding: 0;
            border-radius: 0;
            width: prVars.$toolbar-side-nav-height;
            height: prVars.$toolbar-side-nav-height;
            min-width: prVars.$toolbar-side-nav-height;
          }
        }
      }
    }

    .mat-drawer-content {
      display: flex;

      &.ft-content {
        border-radius: 0;
      }
    }
  }
}
