@use "../utils/mixins" as prMixins;
@use "../utils/variables" as prVars ;

@use "tab_nav_bar";
@use "base_history_layout";

@mixin parent-layout() {
  display: flex;
  flex: 1 1 100%;
  overflow: hidden;
  flex-direction: column;
  border-radius: prVars.$ft-border-radius;

  .mat-tab-nav-bar + .mat-toolbar {
    border-radius: 0;

    .mat-tab-link {
      height: prVars.$module-toolbar-height + 1px;
    }
  }

  .mat-toolbar {
    @include prMixins.ft-toolbar;
    // border-bottom: 1px solid #e5e7eb;
    height: auto;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 0 16px;

    .ft-toolbar-datepicker .mat-datepicker-toggle {
      display: flex;
      color: inherit;

      .mat-icon-button {
        margin: 1px calc(prVars.$ft-small-spacer/2) 1px 0;
        width: prVars.$module-toolbar-height - 12px;
        height: prVars.$module-toolbar-height - 12px;
        line-height: prVars.$module-toolbar-height - 12px;
      }
    }
  }

  .ft-content {
    overflow: auto;
    position: relative;
    // background-color: #fff;
    border-radius: prVars.$ft-border-radius;
    border-top: 1px solid #ebebeb;

    h3 {
      margin: 0;
      height: 45px;
      line-height: 45px;
    }

    .bold-text {
      font-weight: 600;
    }
  }

  .mat-tab-group {
    .mat-tab-header {
      // background-color: prVars.$ft-action-background-color;
      border-bottom: solid 1px prVars.$ft-light-border-color;
    }

    .mat-tab-label {
      min-width: 100px;
      padding: 0 prVars.$ft-large-spacer;
      height: prVars.$module-toolbar-height + 1px;
    }

    .mat-tab-body-wrapper {
      flex: 1 1 100%;
      //background: #fff;

      .mat-tab-body-content {
        display: flex;
      }

      .ft-content {
        padding: prVars.$ft-small-spacer;
        border-radius: 0 0 prVars.$ft-border-radius prVars.$ft-border-radius;
      }
    }

    &.with-actions .mat-tab-body-wrapper .ft-content {
      border-radius: 0;
    }
  }

  .mat-tab-group + .ft-content {
    border-radius: 0 0 prVars.$ft-border-radius prVars.$ft-border-radius;
  }

  > .ft-content {
    padding: prVars.$ft-small-spacer;

    &.with-actions {
      border-radius: 0;
    }
  }

  .ft-actions {
    @include prMixins.action-bar;
  }

  & > .ft-actions {
    border-radius: 0 0 prVars.$ft-border-radius prVars.$ft-border-radius;
  }

  .mat-drawer-inner-container .mat-toolbar {
    margin: 0;
  }
}

@mixin apply-parent-layout-theming() {
  .ft-parent-layout {
    @include parent-layout();
    @include base_history_layout.base-history-theming();

    nav.mat-tab-nav-bar {
      @include tab_nav_bar.apply-nav-bar-theme();
    }
  }
}

