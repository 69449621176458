@use "@angular/material/theming" as matTheming;
@use "../utils/variables" as prVars;
@use "../utils/mixins" as prMixins;
@use "~mq-scss/mq" as mq;

@mixin apply-app-container() {
  .ft-app-container {
    flex: 1 1 100%;
    background-color: transparent;
    margin: 16px;

    .ft-drawer-container {
      & > .mat-drawer-inner-container {
        position: relative;
        overflow-x: hidden;
      }

      .ft-drawer-item {
        position: absolute;
        height: calc(100% - #{prVars.$ft-small-spacer * 2});
      }
    }

    > .mat-drawer {
      overflow-x: hidden;
      box-sizing: border-box;
      background-color: inherit;
      width: prVars.$ft-global-drawer-width;
      max-width: prVars.$ft-global-drawer-width;

      @include mq.mq(max, prVars.$min-lim-md) {
        width: prVars.$ft-global-drawer-width - 30px;
      }

      @include mq.mq(inside, prVars.$min-lim-lg, prVars.$min-lim-md) {
        width: prVars.$ft-global-drawer-width - 15px;
      }



      .mat-drawer-inner-container {
        display: flex;

        ft-drawer-links {
          box-shadow: 0px 0px 30px rgba(24, 28, 50, 0.06);
          border-radius: 0;
          overflow: hidden;
          margin: 0;

          .side-nav-links .mat-toolbar .side-nav-search {
            max-width: 100%;
            color: #b1b1b1;
            background-color: rgb(188 188 188 / 20%);
          }
          .side-nav-links .mat-toolbar .side-nav-search::after {
            font-family: 'Material Icons';
            content: "🔍";
            // -webkit-font-feature-settings: 'liga';
          }
          .mat-toolbar{
            border-radius: 0;
        }
          .mat-toolbar.mat-primary {
            background: #ffffff;
            color: white;
        }
        }

      }

      > * {
        @include prMixins.scrollbars(0.3em, $track: false);
      }
    }

    .mat-drawer-content .ft-parent-layout.ft-content-margin {
      margin-left: 16px;
      box-shadow: 0px 0px 30px rgba(24, 28, 50, 0.06);
      border-radius: 10px;
      background-color: white;

      .mat-toolbar {
        justify-content: start;

      }
    }


    .border-radius-top {
      border-top-left-radius: prVars.$ft-border-radius;
      border-top-right-radius: prVars.$ft-border-radius;
    }

    .border-radius-bottom {
      border-bottom-left-radius: prVars.$ft-border-radius;
      border-bottom-right-radius: prVars.$ft-border-radius;
    }

    .mat-drawer-side {
      border: none;
    }
  }
}
