@use "@angular/material/theming";
@use "@angular/material" as mat;
@use "@ft/file-manager/style" as ftFileManager;
@use "style/utils/variables" as prVars;

@use "./style/main_style" as pr;
// with (
//   // $ft-primary-palette: mat.define-palette(mat.$teal-palette, 500),
//   // $ft-accent-palette: mat.define-palette(mat.$teal-palette, 700),
//   $ft-primary-palette: mat.define-palette($pr-electric-blue-palette, 500),
//   $ft-accent-palette: mat.define-palette(mat.$blue-grey-palette, 700),
// );

/* Import the whole file */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Thin.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19")
      format("woff2"),
    url("assets/fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Light.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19")
      format("woff2"),
    url("assets/fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19")
      format("woff2"),
    url("assets/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("assets/fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
    url("assets/fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icomoon/icomoon.eot?79ahl9");
  src: url("assets/fonts/icomoon/icomoon.eot?79ahl9#iefix")
      format("embedded-opentype"),
    url("assets/fonts/icomoon/icomoon.ttf?79ahl9") format("truetype"),
    url("assets/fonts/icomoon/icomoon.woff?79ahl9") format("woff"),
    url("assets/fonts/icomoon/icomoon.svg?79ahl9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@include pr.load-theme();

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-up:before {
  content: "\e900";
  color: #b5b5c3;
}

.icon-down:before {
  content: "\e901";
  color: #b5b5c3;
}

.icon-updown:before {
  content: "\e902";
  color: #b5b5c3;
}

.sb-show-main {
  background-color: #efefef;
}

#storybook-root {
  height: 100%;
}

#storybook-docs {
  height: 100%;
  overflow: auto;
}

.big-dialog {
  max-height: 100% !important;
  max-width: 100% !important;
  // width: 95vw;
  .mat-dialog-container {
    // height: 400px;
    // width: 600px;
    height: 95vh;
    width: 95vw;
    // border-radius: 10px;
    // background: lightcyan;
    // color: #039be5;
  }
}

//@include ftFileManager.load-style();
.mat-toolbar {
  justify-content: normal;
}

// @media only screen and (max-width: 600px) {
//   body {
//   max-height: 760px;
//   }
// }

.block-header {
  position: relative;

  h1 {
    color: #4177fc;
    font-weight: 500;
    // border-bottom: 1px solid #eceff1;
    padding: 6px 0;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    /* text-transform: uppercase;*/
  }

  ::ng-deep .ft-file-manager .ft-parent-layout .mat-toolbar {
    border-bottom: 2px solid #eceff1;
  }

  .prescription-upload-container {
    position: absolute;
    right: 6px;
    top: 3px;

    button {
      width: 30px;
      height: 30px;
      background-color: map-get(prVars.$ft-primary-palette, 50);
      border-radius: 10px;
    }
  }
}

.block-file-field {
  padding: 16px;

  .file-item {
    display: flex;
    border: 1px dashed #cfcfcf;
    border-radius: 999px;
    align-items: center;
    padding: 0 8px;

    .file-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }

    .file-item-name {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-size: 0.9rem;
    }

    button {
      width: 32px;
      height: 32px;
    }
  }
}
