@use "sass:meta";
@use '../utils/variables' as prVars;


@mixin load-icons-styles() {
  @include meta.load-css("@mdi/font/scss/materialdesignicons");

  .mat-icon {
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    &.ft-success {
      color: prVars.$ft-success-color;
    }

    &.ft-warning {
      color: prVars.$ft-warning-color;
    }
  }
}
