@use "sass:meta";


@mixin load-style() {
  @include meta.load-css("~iv-viewer/dist/iv-viewer");
  @include meta.load-css("~pdfjs-dist/web/pdf_viewer");

  .pdfViewer .page {
    border: 9px solid transparent;
  }
}
