@use "@angular/material/theming" as matTheming;
@use "../utils/variables" as prVars;
@use "../utils/mixins" as prMixins;
@use "~mq-scss/mq" as mq;
@use "@angular/material" as mat;

@mixin apply-table-theme() {
  .ft-table-container,
  .table-container {
    display: flex;
    overflow: auto;
    position: relative;
    flex-direction: column;
    margin-top: 25px;
    margin-right: 16px;
    margin-bottom: 25px;
    margin-left: 16px !important;
    &.no-items {
      display: flex;
    }

    .mat-table,
    .mat-paginator,
    .empty-table-cell,
    .ft-table-search-bar {
      // margin-right: prVars.$ft-small-spacer;
      // transition: margin-right 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.has-scroll .ft-table-search-bar {
      // margin-right: prVars.$ft-small-spacer + 3px; // I don't know why
    }

    &.has-pagination {
      display: flex;
      flex-direction: column;

      &.has-scroll .mat-paginator {
        // margin-right: prVars.$ft-small-spacer + 4px; // I don't know why
      }

      .inner-table-container .mat-table {
        border-bottom: none;
      }
    }

    .inner-table-container {
      overflow: auto;
    }

    .ft-table-search-bar {
      flex: none;
      // margin-bottom: prVars.$ft-small-spacer;
      margin-bottom: 31px;
      margin-right: 0;
      padding: 10px 12px;
      background-color: rgba(143, 146, 161, 0.05);
      border-radius: 98px;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

      // border-radius: prVars.$ft-border-radius;
      // border: 1px solid prVars.$ft-light-border-color;
      // background-color: rgba(prVars.$ft-light-border-color, 0.1);

      &.focused {
        border-color: rgba(prVars.$ft-primary-color, 0.5);
      }

      & > .mat-icon,
      & > .mat-icon-button {
        // width: prVars.$table-search-bar-dimension - prVars.$ft-small-spacer;
        // height: prVars.$table-search-bar-dimension - prVars.$ft-small-spacer;
        width: 24px;
        height: 24px;

        &:last-of-type {
          margin-right: prVars.$ft-small-spacer * 1.5;
        }
      }

      & > .mat-icon {
        text-align: center;
        line-height: prVars.$table-search-bar-dimension -
          prVars.$ft-small-spacer;
      }

      .mat-icon {
        transition: color 280ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      & > input {
        border: none;
        outline: none;
        font-size: 14px;
        color: #8f92a1;
        // background: inherit;
        background-color: transparent;
        &::placeholder {
          color: #8f92a1;
        }
      }

      @include mq.mq(max, prVars.$min-lim-md) {
        overflow: hidden;

        > input {
          min-width: 80px !important;
        }

        & > .mat-icon,
        & > .mat-icon-button {
          &:last-of-type {
            margin-right: prVars.$ft-small-spacer !important;
          }
        }

        .ft-button-list-action-bar {
          margin-right: prVars.$ft-small-spacer !important;

          .mat-stroked-button {
            padding: 0 prVars.$ft-medium-spacer !important;
          }
        }

        .ft-datepicker-container {
          input {
            width: 72px !important;
            font-size: 14px !important;
          }

          .mat-datepicker-toggle .mat-icon-button {
            width: 24px !important;
            height: 24px !important;
            line-height: 24px !important;
          }
        }
      }

      // search related
      .ft-unset-button {
        margin-right: prVars.$ft-large-spacer;
      }

      .ft-button-list-action-bar {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: prVars.$ft-large-spacer;

        .mat-stroked-button {
          height: 28px;
          border-radius: 0;
          min-width: unset;
          padding: 0 prVars.$ft-large-spacer;
          border-color: prVars.$ft-light-border-color;

          &:not(:last-of-type) {
            border-right: none;
          }

          &:first-of-type {
            border-radius: prVars.$ft-border-radius 0 0 prVars.$ft-border-radius;
          }

          &:last-of-type {
            border-radius: 0 prVars.$ft-border-radius prVars.$ft-border-radius 0;
          }
        }
      }

      .ft-datepicker-container {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: prVars.$ft-small-spacer;

        input {
          font-size: 15px;
          font-weight: 600;

          width: 80px;
          border: none;
          height: 28px;
          line-height: 28px;

          outline: none;
          padding: 0 3px;
          background: transparent;
        }

        .mat-datepicker-toggle .mat-icon-button {
          width: 28px;
          height: 28px;
          line-height: 28px;
        }
      }
    }

    .mat-table {
      // border: 2px solid prVars.$ft-light-border-color;
      // border-top: none;

      &.empty-table {
        border-bottom: none;
      }

      .mat-header-row,
      .mat-row {
        padding: 0;
        min-height: prVars.$table-line-dimension;
      }
      .mat-row,
      .mat-header-row,
      .mat-footer-row {
        border-bottom: none;
      }

      .mat-header-row {
        padding-right: 10px;
        box-shadow: 0 7px 4px -7px #e1e1e1;
      }

      .mat-row {
        // padding-right: 10px;
        margin: 0 10px;
      }

      .mat-footer-row {
        display: none;
      }

      .mat-header-cell {
        font-size: 14px;
        font-weight: 600;
        color: matTheming.mat-color(matTheming.$mat-blue-gray, 900);

        &.mat-sort-header-disabled {
          .mat-sort-header-arrow {
            display: none;
          }

          .mat-sort-header-button {
            max-width: 100%;
          }
        }

        .mat-sort-header-container {
          align-items: center;
          color: #b5b5c3;
          font-size: 13px;
          font-weight: 500;
        }

        .mat-sort-header-button {
          flex: 1 1 100%;
          text-align: inherit;
          display: inline-block;

          @include prMixins.text-ellipsis($max-width: calc(100% - 18px));
        }
      }

      mat-cell,
      mat-header-cell {
        display: inline-block;
      }

      td.mat-cell,
      th.mat-header-cell {
        display: table-cell;
      }

      .mat-cell,
      .mat-header-cell {
        padding: 0 prVars.$ft-large-spacer;
        height: prVars.$table-line-dimension;
        line-height: prVars.$table-line-dimension;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 14px;
        color: #464e5f;

        &:not(:first-of-type) {
          // border-left: 1px solid prVars.$ft-light-border-color;
        }
      }

      mat-header-row,
      .mat-header-row th.mat-header-cell {
        // border-top: 2px solid prVars.$ft-light-border-color;
        // border-bottom: 2px solid matTheming.mat-color(prVars.$ft-primary-palette, 600);
        // border-bottom: none;
        background-color: #fafafa;
        height: 43px;
        border-radius: 6px;
      }

      .mat-row {
        // border-color: #eceff1;
        height: 53px;
        border-bottom: 1px solid #ededed;

        &.ft-odd-row {
          //background-color: #f6f7f8;
        }

        &.ft-detail-row {
          height: auto;
          min-height: unset;

          &.ft-detail-closed {
            border: none;
          }

          .mat-cell {
            height: auto;
          }
        }

        &.ft-row-expended {
          border-bottom-color: transparent;
        }

        &.ft-last-row {
          border: none;
        }

        &.ft-selectable-row,
        &.ft-click-able-row {
          cursor: pointer;
          user-select: none;
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
        }

        &.ft-row-selected {
          background-color: rgba(prVars.$ft-primary-color, 0.075);
        }
      }

      .mat-row:last-of-type {
        border-bottom: 0px solid transparent;
      }

      .ft-multi-selection {
        padding: 0;
        display: flex;
        justify-content: center;
        max-width: prVars.$table-line-dimension + 10px;

        .mat-checkbox-disabled {
          cursor: pointer;

          &:not(.mat-checkbox-checked) .mat-checkbox-frame {
            border-color: rgba(black, 0.54);
          }

          &.mat-checkbox-checked.mat-accent,
          &.mat-checkbox-indeterminate.mat-accent {
            .mat-checkbox-background {
              background-color: prVars.$ft-accent-color;
            }
          }
        }
      }

      .pr-number-cell {
        max-width: 100px;
        text-align: right;

        &.mat-header-cell .mat-sort-header-button {
          order: 1;
          flex-direction: row;
          align-items: flex-end;
        }

        .mat-sort-header-arrow {
          order: 0;
          margin: 0 prVars.$ft-small-spacer 0 0;
        }
      }

      .ft-date-cell  {
        max-width: 170px;
      }
      .ft-time-cell {
        max-width: 100px;
      }

      .ft-datetime-cell {
        max-width: 108px;
      }

      .ft-icon-cell,
      .ft-round-cell,
      .ft-boolean-cell,
      .ft-badge-cell {
        &.mat-header-cell {
          text-align: center;

          /*.mat-sort-header-button {
            flex-direction: column;
          }*/
        }
      }

      .ft-icon-cell,
      .ft-boolean-cell {
        max-width: 70px;

        &.mat-cell {
          display: flex;
          align-items: center;
          justify-content: center;

          // .mat-icon {
          //   font-size: 25px;
          // }
        }
      }

      .ft-badge-cell.mat-cell {
        display: flex;

        .ft-table-value-container {
          color: #fff;
          // width: 100%;
          // font-weight: bold;
          text-align: center;
          display: inline-block;

          // font-size: prVars.$ft-large-spacer;
          // border-radius: prVars.$ft-border-radius;
          // height: prVars.$ft-large-spacer * 1.5;
          // line-height: prVars.$ft-large-spacer * 1.5;
          // padding: prVars.$ft-small-spacer/3 prVars.$ft-small-spacer;
          min-width: 50px;
          max-width: fit-content;
          padding: 0 10px;
          border-radius: 999em;
          line-height: 22px;
        }
      }

      .ft-color-cell .ft-table-value-container {
        display: flex;
        align-items: center;
        margin-left: calc(prVars.$ft-large-spacer * -1/3);

        .mat-icon {
          font-size: 20px;
          margin-right: prVars.$ft-medium-spacer;
        }
      }

      .ft-progress-cell .ft-table-value-container {
        display: flex;
        align-items: center;

        > span {
          min-width: 50px;
          text-align: right;
          margin-right: prVars.$ft-large-spacer;
        }
      }

      .ft-input-cell,
      .ft-date-input-cell {
        input {
          height: 100%;
          border: none;
          outline: none;
          box-sizing: border-box;
          background-color: transparent;
        }
      }

      .ft-input-cell input {
        width: 100%;
      }

      .ft-date-input-cell {
        max-width: 130px;

        &.mat-cell {
          display: flex;
          align-items: center;
        }

        input {
          flex: 1 1 100%;
          max-width: calc(100% - #{prVars.$table-line-dimension - 4px});
        }

        .mat-datepicker-toggle,
        .mat-icon-button {
          height: prVars.$table-line-dimension - 4px;
          width: prVars.$table-line-dimension - 4px;
          min-width: prVars.$table-line-dimension - 4px;
        }

        .mat-icon.mdi-menu-down {
          width: 25px;
          height: 25px;
          font-size: 25px;
          line-height: 25px;
          color: rgba(0, 0, 0, 0.42);
        }
      }

      .ft-round-cell {
        max-width: 65px;
        text-align: center;

        &.mat-cell > span {
          color: #fff;
          height: 20px;
          font-size: 14px;
          min-width: 20px;
          margin-left: 4px;
          line-height: 20px;
          border-radius: 5px;
          text-align: center;

          font-weight: 600;
          display: inline-block;
          background-color: mat.get-color-from-palette(
            prVars.$ft-primary-palette,
            600
          );
        }
      }

      @for $i from 1 through 7 {
        .ft-action-cell-#{$i},
        .ft-actions-cell-#{$i} {
          max-width: $i * (prVars.$table-line-dimension - 4px);
          min-width: $i * (prVars.$table-line-dimension - 4px);

          &.mat-cell {
            display: flex;
            align-items: center;
            justify-content: center;

            .mat-icon-button {
              height: prVars.$table-line-dimension - 4px;
              width: prVars.$table-line-dimension - 4px;
              min-width: prVars.$table-line-dimension - 4px;

              &:not(:last-of-type) {
                margin-right: calc(prVars.$ft-small-spacer/2);
              }
            }
          }
        }
      }
    }

    .mat-table + .mat-paginator,
    .empty-table-cell + .mat-paginator {
      border-top: none;
    }

    .mat-paginator {
      // border: 2px solid prVars.$ft-light-border-color;
      margin-right: 0;
      border-top: 1px solid #ededed;
      background-color: #fafafa;
      .mat-paginator-outer-container {
        // height: 50px;
        // align-items: center;
        margin: 7px 0;
        .mat-paginator-container {
          padding: 0 prVars.$ft-large-spacer;
          height: prVars.$table-line-dimension;
          min-height: prVars.$table-line-dimension;

          .mat-paginator-page-size {
            align-items: center;

            .mat-paginator-page-size-select {
              margin: 0 0 0 prVars.$ft-small-spacer;

              .mat-form-field-wrapper {
                padding: 0;
              }

              .mat-form-field-infix {
                border: none;
              }

              .mat-form-field-underline {
                display: none;
              }
            }
          }

          .mat-paginator-range-actions {
            .mat-icon-button {
              width: prVars.$table-line-dimension - 4px;
              height: prVars.$table-line-dimension - 4px;
              line-height: prVars.$table-line-dimension - 4px;
            }

            .mat-paginator-icon {
              width: 24px;
            }
          }
        }
      }
    }

    .empty-table-cell {
      display: flex;
      align-items: center;

      // border: 2px solid prVars.$ft-light-border-color;
      border-top: none;

      color: prVars.$ft-warn-color;
      padding: 0 prVars.$ft-large-spacer;
      height: prVars.$table-line-dimension;
    }
  }
  .mat-cell {
    color: unset;
  }
  .table-container.bordered{
    border: 1px solid prVars.$ft-light-border-color;
    margin-top: 0;
  }
}
