@use "../../style/utils/variables" as prVars;
@use "../../style/utils/mixins" as prMixins;
@use "~mq-scss/mq" as mq;
@use '../utils/variables' as ftVars;

@mixin base-dialog-style() {
  .mat-dialog-container {
    padding: 0;
    height: auto;
    // background: transparent;
    // border-radius: prVars.$ft-border-radius;
    border-radius: 10px;

    > * {
      display: flex;
      max-height: 100%;
      flex-direction: column;
    }

    .mat-toolbar {
      @include prMixins.ft-toolbar($padding: prVars.$ft-large-spacer, $height: prVars.$toolbar-small-height);
      border-bottom: solid 1px ftVars.$ft-light-border-color;

      > .mat-icon-button:first-of-type {
        margin-left: prVars.$ft-large-spacer;
      }
    }

    .mat-dialog-content, .ft-content {
      margin: 0;
      min-height: 80px;
      // background: #fff;
      position: relative;
      padding: prVars.$ft-medium-spacer;

      ft-generic-view-table {
        overflow: hidden;

        .ft-table-container, .table-container {
          margin: 0;
        }
      }
    }


    .mat-tab-group {
      flex-shrink: 0;

      .mat-tab-header {
        background-color: prVars.$ft-action-background-color;
        border-bottom: solid 1px prVars.$ft-light-border-color;
      }

      .mat-tab-label {
        min-width: 100px;
        padding: 0 prVars.$ft-large-spacer;
        height: prVars.$toolbar-small-height;
      }

      .mat-tab-body-wrapper {
        flex: 1 1 100%;
        background: #fff;

        .mat-tab-body-content {
          display: flex;
        }
      }
    }

    .mat-dialog-actions {
      @include prMixins.action-bar();

      border-top: solid 1px ftVars.$ft-light-border-color;

      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      .mat-flat-button{
        margin: 0 6px;
      }
    }
  }
}

@mixin apply-dialogs-overrides() {
  .ft-large-dialog, .ft-medium-dialog, .ft-small-dialog {
    @include base-dialog-style();
  }

  .ft-large-dialog {
    width: 65%;
    max-height: 90%;
    min-width: 900px;

    @include mq.mq(inside, prVars.$min-lim-lg, prVars.$min-lim-md) {
      min-width: 1000px;
    }

    @include mq.mq(max, prVars.$min-lim-md) {
      min-width: 1000px;
    }
  }

  .ft-medium-dialog {
    width: 45%;
    min-width: 700px;
  }

  .ft-small-dialog {
    width: 450px;
  }

  .ft-download-progress-dialog .mat-dialog-container {
    padding: 24px;
  }

  .ft-prompt-dialog {
    width: 400px;

    .mat-dialog-container {
      padding: prVars.$ft-large-spacer;
    }

    .mat-dialog-title {
      font-weight: 500;
      line-height: prVars.$module-action-buttons-height;
    }

    .mat-form-field, .mat-dialog-title {
      margin: 0 0 prVars.$ft-small-spacer 0;
    }

    .mat-dialog-actions {
      padding: 0;
      margin-bottom: 0;
      min-height: unset;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .mat-stroked-button {
      min-width: unset;
      height: prVars.$module-action-buttons-height;
      padding: 0 prVars.$ft-large-spacer 0 prVars.$ft-medium-spacer*0.5;

      &:not(:last-of-type) {
        margin-right: prVars.$ft-large-spacer;
      }

      &::ng-deep .mat-button-wrapper {
        height: prVars.$module-action-buttons-height - 2px;
        line-height: prVars.$module-action-buttons-height - 2px;

        & > span, & > .mat-icon {
          height: inherit;
          line-height: inherit;
        }
      }
    }

    .mat-stroked-button .mat-icon {
      margin-right: prVars.$ft-medium-spacer;
    }
  }
}
