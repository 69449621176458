@mixin apply-slide-toggle-theme() {
  $mat-slide-toggle-thumb-size: 16px;
  $mat-slide-toggle-bar-border-radius: 6px;
  $mat-slide-toggle-height: 18px;
  $mat-slide-toggle-spacing: 6px;
  $mat-slide-toggle-ripple-radius: 20px;
  $mat-slide-toggle-bar-width: 28px;
  $mat-slide-toggle-bar-height: 10px;
  $mat-slide-toggle-bar-track-width: $mat-slide-toggle-bar-width - $mat-slide-toggle-thumb-size;

  .mat-slide-toggle {
    height: $mat-slide-toggle-height;
    line-height: $mat-slide-toggle-height;

    &.mat-checked .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
      transform: translate3d($mat-slide-toggle-bar-track-width, 0, 0);
    }

    [dir='rtl'] .mat-slide-toggle-label-before .mat-slide-toggle-bar,
    .mat-slide-toggle-bar {
      margin-right: $mat-slide-toggle-spacing;
    }

    [dir='rtl'],
    .mat-slide-toggle-label-before {
      .mat-slide-toggle-bar {
        margin-left: $mat-slide-toggle-spacing;
      }
    }

    .mat-slide-toggle-thumb-container {
      $thumb-bar-vertical-padding: ($mat-slide-toggle-thumb-size - $mat-slide-toggle-bar-height) * 0.5;

      width: $mat-slide-toggle-thumb-size;
      height: $mat-slide-toggle-thumb-size;
      top: $thumb-bar-vertical-padding * -1;
    }

    .mat-slide-toggle-thumb {
      height: $mat-slide-toggle-thumb-size;
      width: $mat-slide-toggle-thumb-size;

    }

    .mat-slide-toggle-bar {
      width: $mat-slide-toggle-bar-width;
      height: $mat-slide-toggle-bar-height;
      border-radius: $mat-slide-toggle-bar-border-radius;
    }

    .mat-slide-toggle-input {
      left: $mat-slide-toggle-thumb-size * 0.5;
    }

    .mat-slide-toggle-ripple {
      border-radius: 50%;
      width: $mat-slide-toggle-ripple-radius * 2;
      height: $mat-slide-toggle-ripple-radius * 2;
      top: calc(50% - #{$mat-slide-toggle-ripple-radius});
      left: calc(50% - #{$mat-slide-toggle-ripple-radius});
    }

    .mat-slide-toggle-content {
      font-size: 14px;
    }
  }
}
