@use '@angular/material' as mat;
@use '../utils/variables' as ftVars;

@mixin apply-loader-style() {
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
  }

  .ng-busy {
    z-index: 9002;
  }

  .ng-busy,
  .ng-busy > *,
  .ng-busy > ng-component > * {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  .ng-busy-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9001;
    background: #fff;
    opacity: .5;
  }

  .ft-app-loader {
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    position: absolute;
    background-color: ftVars.$body-background-color;

    display: flex;
    align-items: center;
    justify-content: center;

    &.fade-out {
      animation: fadeOut .5s ease-in-out 1 normal forwards;
    }

    &.animation-end {
      display: none;
    }

    .spinner {
      width: 100px;
      text-align: center;
    }

    .spinner > div {
      width: 25px;
      height: 25px;

      border-radius: 100%;
      display: inline-block;
      background-color: mat.get-color-from-palette(ftVars.$ft-grey-palette, 800);
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      animation-delay: -0.16s;
    }
  }
}
