@mixin apply-inputs-overrides() {
  input[type="time"] {
    flex-flow: row;
    min-width: 73px;
    text-align: right;
    display: inline-flex;
    justify-content: space-around;

    &::-webkit-inner-spin-button {
      order: 1;
      display: none;
      margin-left: 0;
      height: inherit;
    }

    &::-webkit-datetime-edit {
      order: 2;
    }

    &:focus::-webkit-inner-spin-button {
      display: inline-block;
    }

    &::-webkit-clear-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  input[type="number"], input[prNumber], input.ft-number {
    // text-align: right;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
