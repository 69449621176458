@use "../utils/variables" as prVars;

@mixin apply-grid() {
  .search-bar-container {
    height: 44px;
    position: relative;
    // position: fixed;
    // z-index: 1;
    // width: calc(100% - 2 * 24px);

    .search-bar {
      display: flex;
      background-color: #fff;
      height: 44px;
      // border-radius: 999em;
      // border: 1px solid #e5e5e5;
      // margin-bottom: 24px;
      box-shadow: 0px 4px 10px rgb(6 16 66 / 8%);

      .search-bar-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }

      .search-bar-input {
        flex-grow: 1;

        input {
          width: 100%;
          border: none;
          outline: none;
          font-size: 14px;
          color: #8f92a1;
          background-color: transparent;
        }
      }

      .pr-filter {
        position: absolute;
        min-width: 36px;
        width: 36px;
        height: 36px;
        padding: 0;
        border-radius: 999em;
        background-color: map-get(prVars.$ft-primary-palette, 50);
        top: 50%;
        right: 5px;
        transform: translate(0, -50%);

        mat-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .add-btn {
      position: fixed;
      bottom: 16px;
      right: 16px;
      width: 45px;
      height: 45px;
      z-index: 10;
    }
  }

  // .search-bar-container-for-tablet {
  //   width: calc(100% - 106px);
  // }

  .grid {
    // &.card-grid-md {
    //   padding-bottom: 24px; // for other than mobile
    // }

    &.card-grid-mobile {
      padding-bottom: 100px; // for mobile tabs
    }

    & > li {
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(6, 16, 66, 0.08);
      border-radius: 10px;
      border: 1px solid #e9e9e9;

      .attributes-header {
        display: flex;
        color: prVars.$ft-primary-color;
        font-size: 18px;
        padding: 8px 14px;
        border-bottom: 1px solid #e9e9e9;
        //background-image: linear-gradient(
        //  135deg,
        //  #e7e7e780 10%,
        //  transparent 0,
        //  transparent 50%,
        //  #e7e7e780 0,
        //  #e7e7e780 60%,
        //  transparent 0,
        //  transparent
        //);
        background-size: 7.07px 7.07px;
        align-items: center;

        & > img {
          width: 40px;
          height: 40px;
          border-radius: 36px;
          background-color: #959797;
        }

        & > div {
          display: flex;
          align-items: center;
          .item-title {
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 9px 0 9px 10px;
            cursor: pointer;
          }
        }

        & > .item-prescription-name {
          font-size: 18px;
          overflow: hidden;
          margin-left: 10px;
          flex-grow: 1;
          padding-right: 10px;

          & > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
          }


        }

        .item-prescription-count {
          display: flex;
          height: 20px;
          width: 20px;
          // position: absolute;
          // top: 50%;
          // right: 16px;
          // transform: translate(0, -50%);
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          padding: 0;
          // text-shadow: 0 1px 1px #00000070;
          span {
            background: inherit;
            background-clip: text;
            color: transparent;
            filter: invert(1) grayscale(1) contrast(50);
            font-weight: bold;
            font-size: 0.8rem;
          }
        }
      }

      .attributes-container {
        padding: 14px;
        overflow: hidden;
        background: linear-gradient(rgb(0 0 0 / 3%), white);
        border-radius: 0 0 10px 10px;

        .attribute-item {
          display: flex;
          margin-bottom: 10px;
          float: left;
          margin-right: 22px;

          .attribute-icon {
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            mat-icon {
              background-color: #ecedef;
              border-radius: 10px;
              height: 30px;
              width: 30px;
              color: #464e5f;
              line-height: 30px;
              font-size: 20px;
            }
          }

          .attribute-key {
            color: rgba(70, 78, 95, 0.4);
          }

          .attribute-value {
            color: #464e5f;
          }
        }
      }
    }
  }
}
