@use "@angular/material/theming" as matTheming;
@use "../utils/variables" as prVars;
@use "../utils/mixins" as prMixins;
@use "sass:math";

@mixin apply-form-overrides() {
  .mat-form-field .mat-form-field-wrapper {
    transition: padding-bottom 0.2s;
    padding-bottom: calc(prVars.$ft-medium-spacer/2);

    .mat-form-field-underline {
      bottom: calc(prVars.$ft-medium-spacer/2);
    }

    .mat-form-field-suffix,
    .mat-form-field-prefix {
      top: 0;

      .mat-icon-button {
        //top: 2px;
        width: 24px;
        height: 24px;

        .mat-icon {
          font-size: 20px;
        }
      }
    }
  }

  .mat-form-field {
    mat-label {
      // font-size: 0.85rem;
    }
  }
  
  .mat-form-field.mat-form-field-should-float .label {
    font-size: 13px;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-0.58125em) scale(0.75) perspective(100px)
      translateZ(0.00106px);
  }

  /* Border */
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #ebebeb;
    // color: #d3d3d3;
    // border-width: 1px !important;
  }
  /* Font color */
  // input.mat-input-element {
  //   color: green;
  // }

  .mat-form-field {
    &.mat-form-field-appearance-outline.with-error-label.mat-form-field-invalid
      .mat-form-field-wrapper
      .mat-form-field-subscript-wrapper {
      margin-top: prVars.$ft-large-spacer;
    }

    /* Border */
    // &.mat-form-field-appearance-outline &.mat-form-field-outline {
    //   color: red;
    // }

    &.with-error-label.mat-form-field-invalid .mat-form-field-wrapper {
      padding-bottom: prVars.$ft-large-spacer;

      .mat-form-field-underline {
        bottom: prVars.$ft-large-spacer;
      }

      .mat-form-field-subscript-wrapper {
        margin-top: calc(prVars.$ft-large-spacer / 1.5);
      }
    }

    &.ft-radio-field,
    &.ft-checkbox-field {
      .mat-form-field-underline {
        display: none;
      }
    }

    &.ft-checkbox-field.ft-compact-field {
      mat-slide-toggle {
        margin-bottom: -1 * prVars.$ft-medium-spacer;
      }
    }

    textarea.mat-input-element {
      resize: none;
    }

    .mat-form-field-suffix .mat-datepicker-toggle .mat-icon.mdi-calendar-today {
      font-size: 18px;
    }

    &.mat-form-field-appearance-fill {
      .mat-form-field-flex {
        padding: prVars.$ft-small-spacer prVars.$ft-small-spacer 0
          prVars.$ft-small-spacer;
      }

      .mat-form-field-infix {
        border-top-width: prVars.$ft-small-spacer * 2;
        padding: calc(prVars.$ft-small-spacer/3) 0 prVars.$ft-small-spacer * 1.5 0;
      }

      .mat-form-field-label {
        top: prVars.$ft-small-spacer * 3.3333;
      }

      &.mat-form-field-can-float.mat-form-field-should-float
        .mat-form-field-label {
        transform: scale(0.75) translateY(-#{prVars.$ft-small-spacer * 2.666});
      }
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-infix {
        padding: 0.5em 0px;
        .mat-form-field-label {
          top: 2.05em;
        }
      }
    }

    .mat-form-field-label-wrapper {
      top: -1.5em;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      .mat-icon.ft-color-picker-preview {
        @include prMixins.icon-resize(24px);

        font-size: 20px;
        //vertical-align: sub;
        transition: color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  .cdk-overlay-pane {
    @include prMixins.scrollbars(0.2em, $track: false);

    .mat-autocomplete-panel,
    .mat-select-panel {
      .mat-optgroup .mat-optgroup-label {
        height: prVars.$ft-select-height - 2px;
        line-height: prVars.$ft-select-height - 2px;

        padding: 0 prVars.$ft-medium-spacer;
      }

      .mat-option {
        height: prVars.$ft-select-height;
        padding: 0 prVars.$ft-medium-spacer;
        line-height: prVars.$ft-select-height;

        .mat-option-text {
          user-select: none;
        }

        .mat-pseudo-checkbox {
          width: 16px;
          height: 16px;
          margin-right: prVars.$ft-large-spacer;

          &::after {
            top: 1px;
            left: 0;
            width: 10px;
            height: 4px;
          }
        }
      }
    }
  }

  .mat-radio-group {
    font-size: 0.9rem;
    // Mat radio button style taken from source and change variable only
    .mat-radio-container {
      width: 20px;
      height: 20px;
    }

    // The outer circle for the radio, always present.
    .mat-radio-outer-circle {
      height: 20px;
      width: 20px;
    }

    // The inner circle for the radio, shown when checked.
    .mat-radio-inner-circle {
      width: 20px;
      height: 20px;
    }

    .mat-radio-label-content {
      height: 20px;
      padding: 0 prVars.$ft-small-spacer;
      line-height: 20px;
    }

    .mat-radio-ripple {
      border-radius: 50%;
      width: prVars.$mat-radio-ripple-radius * 2;
      height: prVars.$mat-radio-ripple-radius * 2;
      top: calc(50% - #{prVars.$mat-radio-ripple-radius});
      left: calc(50% - #{prVars.$mat-radio-ripple-radius});
    }
  }

  // ft-sequence style
  .mat-input-element.ft-sequence-input {
    color: currentColor;
  }

  // monaco editor css
  .ft-monaco-container {
    overflow: hidden;
  }

  // material toggle buttons
  .mat-button-toggle-group {
    border: none;

    & + .mat-button-toggle-group {
      margin-left: prVars.$ft-small-spacer;
    }

    .mat-button-toggle {
      color: matTheming.$light-primary-text;
      background: prVars.$ft-primary-color;

      &.icon-only .mat-button-toggle-label-content {
        padding: 0 calc(prVars.$ft-small-spacer/1.5);
      }

      &.mat-button-toggle-checked {
        background: prVars.$ft-primary-darker-color;
      }
    }

    .mat-button-toggle-button {
      font-size: 14px;
      height: prVars.$module-action-bar-buttons-height;
      min-width: prVars.$module-action-bar-buttons-height;

      .mat-button-toggle-label-content {
        padding: 0 prVars.$ft-small-spacer;
        line-height: prVars.$module-action-bar-buttons-height;
      }
    }
  }

  .mat-flat-button {
    min-width: fit-content !important;
    height: fit-content !important;
  }

  .mat-button {
    border-radius: 999em !important;
  }

  .mat-flat-button.mat-primary,
  .mat-flat-button.mat-accent,
  .mat-flat-button.mat-warn {
    border-radius: 999em;
  }

  .mat-flat-button.mat-accent,
  .mat-flat-button.mat-warn {
    text-shadow: 0 1px 1px #0000003b;
  }

  .mat-flat-button.cancel-btn {
    background-color: #8f92a1;
    border-radius: 999em;
    color: #fff;
  }

  .mat-icon-button,
  .mat-fab:not([class*="mat-elevation-z"]),
  .mat-mini-fab:not([class*="mat-elevation-z"]) {
    box-shadow: none;
    width: 36px;
    height: 36px;
  }

  .mat-raised-button:not([class*="mat-elevation-z"]) {
    border-radius: 999em;
  }

  .mat-raised-button.mat-primary {
    //box-shadow: 0 4px 10px prVars.$ft-primary-color;
  }
  .mat-raised-button.mat-warn {
    //box-shadow: 0 4px 10px prVars.$ft-warn-color;
  }

  .mat-focus-indicator.mat-icon-button.mat-button-base{
    width: 36px;
    height: 36px;
  }
}
