@use "@angular/material/theming" as matTheming;
@use "../utils/variables" as prVars;
@use "../utils/mixins" as prMixins;
@use "~mq-scss/mq" as mq;

@mixin apply-global-overrides() {
  // :root {
  //   --fc-border-color: black;
  //   --fc-daygrid-event-dot-width: 5px;
  // }
  // body {
  //   margin: 0;
  //   height: 100vh;
  //   position: relative;

  //   // font-family: Roboto, sans-serif;
  //   font-family: Inter, sans-serif;
  //   background-color: prVars.$body-background-color;
  // }

  /* Set the default styles for the body element. */
  body,
  html {
    /* Remove any default margin added by the browser. */
    margin: 0;

    /* Make the body fill the entire height and width of the viewport. */
    height: 100%;
    width: 100%;

    /* Allow child elements to be positioned relative to the body. */
    position: relative;

    /* Specify the font to be used for text inside the body. */
    font-family: "Inter", sans-serif;

    /* Hide any content that overflows outside the body. */
    overflow: hidden;
  }

  .tag {
    background-color: #d7f0e5;
    color: #007b45;
    // border: 1px solid #6FCFA5;
    padding: 2px 10px;
    border-radius: 999em;
    font-weight: 500;
    font-size: 0.9375rem;
    text-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    // text-transform: uppercase;
    text-transform: lowercase;
    max-width: min-content;
  }

  .tag-standard {
    background-color: #b3eeff;
    color: #003366;
  }

  .tag-done {
    background-color: #d7f0e5;
    color: #007b45;
  }

  .tag-not-done {
    background-color: #ffe6cc;
    color: #8b3e00;
  }

  .centred-add-btn {
    display: flex;
    gap: prVars.$pr-standard-spacer;
    padding: calc(prVars.$pr-standard-spacer/2) prVars.$pr-standard-spacer;
    justify-content: center;
    align-items: center;
    background-color: prVars.$pr-background-primary;
    border-radius: prVars.$pr-standard-border-radius;
    box-shadow: prVars.$pr-shadow-settings;
  }
  .settings-add-btn {
    display: flex;
    gap: prVars.$pr-standard-spacer;
    padding: calc(prVars.$pr-standard-spacer/2) prVars.$pr-standard-spacer;
    justify-content: start;
    align-items: center;
    background-color: prVars.$pr-background-primary;
    border-radius: prVars.$pr-standard-border-radius;
    box-shadow: prVars.$pr-shadow-settings;
    span {
      text-align: left;
    }
  }

  .custom-snackbar {
    // background-color: #0043ff;
    // background-color: #03ac13;
    // color: rgb(255 255 255 / 90%);

    background-color: #ffe477;
    color: rgb(62 47 0);
    box-shadow: 0 3px 5px #00000000;
    border: 1px solid #3e2f0026;
    .btn {
      font-weight: 700;
    }
  }

  .fancy-name {
    border-radius: 4px;
    border: 1px solid prVars.$pr-divider-color2;
    padding: 0 calc(prVars.$pr-standard-spacer/2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
  }

  .fade {
    position: relative;
    height: 3.6em; /* exactly three lines */
  }
  .fade::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.2em;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 50%
    );
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 50%;
  }

  .arrow::before,
  .arrow::after {
    position: relative;
    content: "";
    display: block;
    width: 7px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.87);
    transition: 0.3s ease-in-out;
  }

  .arrow::before {
    transform: rotate(45deg);
  }

  .arrow::after {
    left: 5px;
    top: -1px;
    transform: rotate(-45deg);
  }

  .arrow.active::before {
    transform: rotate(-45deg);
  }

  .arrow.active::after {
    transform: rotate(45deg);
  }

  .global-badge {
    // color: inherit;
    // filter: invert(100%);
    // @include text-contrast($pr-badge-color);
    background: inherit;
    background-clip: text;
    color: transparent;
    // color: white;
    // mix-blend-mode: difference;
    filter: invert(1) grayscale(1) contrast(50);
    font-weight: bold;
    font-size: 0.8rem;
  }

  .nothing-to-display {
    background-color: #fff1bf;
    border-radius: 5px;
    padding: 8px 14px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1215686275);
    color: #574e2d;
    // border: 1px solid #e3d8b1;
    width: calc(100% - 50px);
    height: fit-content;
    margin: 10px 0;
  }

  mat-icon.svg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .mat-elevation-z4 {
    box-shadow: 0px 4px 10px rgba(6, 16, 66, 0.08);
  }

  .mat-menu-panel {
    border-radius: 10px !important;
    margin-top: 6px;
    border: 1px solid rgba(0, 0, 0, 0.0901960784);
    background: rgb(255 255 255 / 90%);
    backdrop-filter: blur(33px);
    min-height: fit-content !important;
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    & > * {
      padding: 0 16px !important;
    }
  }

  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    background: prVars.$ft-primary-color;
    color: map-get(prVars.$ft-primary-palette, default-contrast);
    mat-icon {
      color: #fff;
    }
  }

  .mat-menu-item {
    line-height: 38px !important;
    height: 38px !important;
    font-weight: 500;
    font-size: 0.8rem;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.0901960784);
  }

  .mat-card:not([class*="mat-elevation-z"]) {
    padding: 0;
    box-shadow: 0px 4px 10px rgba(6, 16, 66, 0.08);
    border-radius: 10px;
    border: 1px solid #00000017;
    .mat-card-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-bottom: 1px solid #00000017;
      .mat-card-title {
        margin-bottom: 0;
        font-size: 1.1rem;
        color: prVars.$ft-accent-color;
      }
    }
  }

  .mat-card-content {
    padding: 16px;
  }

  .pr-breadcrumb {
    background: none;
    font-size: 0.7em;
    margin: 0;
    color: prVars.$ft-primary-color;
    font-weight: 400;
    flex-grow: 1;
    li {
      list-style: none;
      float: left;
      margin: 5px;
      display: flex;
      align-items: center;
    }
    li::after {
      content: ">";
      color: darkgrey;
      margin-left: 10px;
    }
    li:last-child {
      margin-right: 20px;
      color: #8f92a1;
      &::after {
        content: "";
      }
    }
  }

  .action-btn {
    position: fixed !important;
    bottom: 30px !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 55px !important;
    // min-width: 55px !important;
    width: 55px !important;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%) !important;
    border-radius: 999em !important;
    padding: 0 !important;
    z-index: 101 !important;
    mat-icon {
      font-size: 28px;
      width: 27px;
      height: 27px;
      line-height: 28px;
    }
  }

  .ft-app-root,
  .ft-main-component {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ft-busy-container {
    position: relative;
  }

  * {
    letter-spacing: 0.01em;
    @include prMixins.scrollbars(0.3em);
    font-size: 0.95rem;
  }

  .mat-menu-panel.ft-small-menu {
    min-width: 120px;
    max-height: 250px;

    //max-width: 120px;

    @include prMixins.scrollbars(0.25em, $track: false);

    border-radius: prVars.$ft-border-radius;

    .mat-menu-content:not(:empty) {
      padding: 0;

      .mat-menu-item {
        display: flex;
        align-items: center;

        height: 25px;
        line-height: 25px;
        padding: 0;

        span {
          font-size: 14px;
        }

        .mat-icon {
          height: 25px;
          font-size: 18px;
          line-height: 25px;
          margin-right: prVars.$ft-small-spacer;
        }
      }
    }
  }

  .ft-content-margin {
    margin: prVars.$ft-small-spacer;
  }

  .no-padding {
    padding: 0 !important;
  }

  .ft-padding {
    padding: prVars.$ft-small-spacer;
  }

  .ft-empty-content {
    font-size: 14px;
    font-weight: 500;
    // margin: prVars.$ft-small-spacer;
    border-radius: prVars.$ft-border-radius;

    display: flex;
    align-items: center;
    overflow-y: hidden;

    height: 32px;
    // background-color: matTheming.mat-color(
    //   prVars.$ft-warning-palette,
    //   500,
    //   0.3
    // );
    background-color: rgba(249, 171, 0, 0.2);
    color: rgb(179, 135, 40);
    padding: 0 16px;
    margin: 16px 30px !important;

    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
      margin: 0 prVars.$ft-small-spacer;

      // color: matTheming.mat-color(prVars.$ft-warning-palette, 700);
    }

    span {
      height: 28px;
      line-height: 28px;
      margin-right: prVars.$ft-small-spacer;
      // color: matTheming.mat-color(prVars.$ft-grey-palette, 200);

      @include prMixins.text-ellipsis;
    }
  }

  .ws-connection-lost {
    width: 100%;
    height: 100%;
    max-width: 100%;

    > .mat-dialog-container {
      height: 100%;
      border-radius: 0;
      color: prVars.$ft-warning-color;
      background-color: rgba(0, 0, 0, 0.3);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div.hr-container {
    display: flex;
    align-items: center;
    margin: calc(prVars.$ft-small-spacer/2) 0;

    font-size: 13px;
    font-weight: 500;
    color: prVars.$ft-primary-color;
  }

  hr.ft-sep {
    width: 85%;
    border: 1px dashed prVars.$ft-medium-border-color;

    &.horizontal-sep {
      width: auto;
      border: 1px solid prVars.$ft-light-border-color;
      margin: (-1 * prVars.$ft-small-spacer) (2 * prVars.$ft-small-spacer);
    }

    &.with-text {
      height: 0;
      width: auto;
      flex: 1 0 auto;
      margin: 0 prVars.$ft-small-spacer * 2;
    }
  }

  // global colors
  .ft-primary-color {
    color: prVars.$ft-primary-color;

    &.mat-icon {
      color: prVars.$ft-primary-color !important;
    }
  }

  .ft-accent-color {
    color: prVars.$ft-accent-color;

    &.mat-icon {
      color: prVars.$ft-accent-color !important;
    }
  }

  .ft-warn-color {
    color: prVars.$ft-warn-color;

    &.mat-icon {
      color: prVars.$ft-warn-color !important;
    }
  }

  .ft-success-color {
    color: prVars.$ft-success-color;

    &.mat-icon {
      color: prVars.$ft-success-color !important;
    }
  }

  .ft-warning-color {
    color: prVars.$ft-warning-color;

    &.mat-icon {
      color: prVars.$ft-warning-color !important;
    }
  }

  //printing
  .ft-print-frame {
    top: -9999px;
    position: absolute;
  }

  .ft-file-manager {
    min-height: 174px;
    button {
      color: prVars.$ft-accent-color;
    }
    .mat-elevation-z1 {
      box-shadow: none;
    }
    .ft-parent-layout .mat-toolbar {
      height: 55px;
      border-bottom: 2px solid prVars.$ft-primary-color;
      padding: 0;
    }
    .ft-parent-layout .mat-toolbar h2 {
      color: prVars.$ft-primary-color;
      font-size: 1rem;
      font-weight: 500;
      padding: 0 16px;
    }
    .ft-parent-layout.mat-elevation-z1.ft-content-margin {
      // box-shadow: 0px 4px 10px rgba(6, 16, 66, 0.08);
      // border-radius: 10px;
      // border: 1px solid rgba(0, 0, 0, 0.0901960784);
    }
    .ft-content-margin {
      margin: 0;
    }
  }

  .mat-form-field.mat-focused .mat-form-field-outline-start,
  .mat-form-field.mat-focused .mat-form-field-outline-gap,
  .mat-form-field.mat-focused .mat-form-field-outline-end {
    // color: black;
    border-width: 2px !important;
  }
  .mat-form-field .mat-form-field-outline-start,
  .mat-form-field .mat-form-field-outline-gap,
  .mat-form-field .mat-form-field-outline-end {
    // color: black;
    border-width: 1.5px !important;
    border-color: prVars.$ft-grey-border-color;
  }
}
