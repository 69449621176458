@use "../utils/variables" as prVars ;


@mixin apply-datepicker-overrides() {
.mat-calendar {
  height: 340px;

  .mat-calendar-header {
    padding: prVars.$ft-small-spacer prVars.$ft-small-spacer 0 prVars.$ft-small-spacer;

    .mat-button, .mat-icon-button {
      height: 30px;
      line-height: 30px;
    }

    .mat-button {
      padding: 0 prVars.$ft-medium-spacer;
      margin-left: prVars.$ft-large-spacer;
    }

    .mat-icon-button {
      width: 30px;
    }

    .mat-calendar-next-button::after, .mat-calendar-previous-button::after {
      margin: 10px;
    }
  }

  .mat-calendar-content {
    padding: 0 prVars.$ft-small-spacer prVars.$ft-small-spacer prVars.$ft-small-spacer;
  }
}
}
