@use '@angular/material' as mat;
@use '../utils/variables' as ftVars;


@function rem($multiplier) {
  $font-size: 10px;
  @return $multiplier * $font-size;
}

@mixin scrollbars($size, $track:true, $thumbs-color: mat.get-color-from-palette(ftVars.$ft-accent-palette, 200, .75), $track-color: mat.get-color-from-palette(ftVars.$ft-accent-palette, 50, .8)) {
  *::-webkit-scrollbar-thumb {
    background: $thumbs-color;
  }

  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-track {
    @if ($track) {
      background: $track-color;
    } @else {
      display: none;
    }
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-track-piece {
    display: none;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  ::-webkit-resizer {
    display: none;
  }
}

@mixin ft-toolbar($padding: ftVars.$ft-small-spacer, $height: ftVars.$module-toolbar-height) {
  height: $height;
  position: relative;
  min-height: $height;
  padding: 0 $padding;
  // border-radius: ftVars.$ft-border-radius ftVars.$ft-border-radius 0 0;
  background: transparent;
  color: ftVars.$ft-accent-color;

  &.mat-primary .input-container {
    background: mat.get-color-from-palette(ftVars.$ft-primary-palette, 300);
  }

  &.mat-accent .input-container {
    background: mat.get-color-from-palette(ftVars.$ft-accent-palette, 300);
  }

  .ft-module-icon {
    display: flex;
    align-items: center;
    height: inherit;
    margin-right: $padding;
    width: $height - $padding;
    line-height: $height;

    > .mat-icon-button, > .mat-icon {
      text-align: center;
      width: $height - $padding;
      height: $height - $padding;
      line-height: $height - $padding;

      &[disabled] {
        opacity: .75;
        color: inherit;
      }
    }
  }

  h2 {
    height: $height;
    line-height: $height;
    font-size: 1rem;

    @include text-ellipsis();
  }

  .ft-toolbar-datepicker {
    $toolbar-input-height: $height - 10px;

    display: flex;
    height: $height - 10px;
    background: rgba(0, 0, 0, .1);
    border-radius: ftVars.$ft-border-radius;

    .mat-input-element {
      margin: 0;
      color: #fff;
      width: 100px;
      height: inherit;
      font-size: 14px;
      line-height: $toolbar-input-height;
      padding: 0 0 0 ftVars.$ft-small-spacer;

      &::placeholder {
        color: inherit;
      }
    }

    .mat-datepicker-toggle {
      display: flex;
      color: inherit;

      .mat-icon-button {
        width: $toolbar-input-height - (ftVars.$ft-extra-small-spacer * 2);
        height: $toolbar-input-height - (ftVars.$ft-extra-small-spacer * 2);
        line-height: $toolbar-input-height - (ftVars.$ft-extra-small-spacer * 2);
        margin: ftVars.$ft-extra-small-spacer ftVars.$ft-extra-small-spacer ftVars.$ft-extra-small-spacer 0;
      }
    }
  }

  > .ft-has-icon-buttons > .mat-button, .mat-button {
    height: inherit;
    line-height: $height;
    padding: 0 $padding 0 ($padding * 0.5);

    min-width: 40px;
    font-weight: 500;
    border-radius: unset;

    &.icon-only {
      width: $height;
      height: $height;
      line-height: $height;
    }

    &.no-icon {
      min-width: unset;
      padding: 0 $padding;
    }

    &:not(.icon-only) .mat-icon {
      height: $height;
      line-height: $height;
      margin-right: ftVars.$ft-small-spacer;
    }
  }

  > .ft-has-icon-buttons > .mat-icon-button, .mat-icon-button {
    height: $height - $padding;
    width: $height - $padding;
    line-height: $height - $padding;
  }

  > .mat-button[disabled], > .mat-icon-button[disabled] {
    color: rgba(white, .75);
  }
}

@mixin text-ellipsis($max-width: 100%) {
  overflow: hidden;
  white-space: nowrap;
  max-width: $max-width;
  text-overflow: ellipsis;
}

@mixin form-field-wrapper() {
  ::ng-deep .form-field-wrapper {
    margin: 20px 0;
    background-color: rgba(146, 147, 154, 0.1);
    padding: 15px 10px 10px;
    border-radius: 5px;
  
    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: #c9c9c9;
    }
  }
}

@mixin icon-resize($dimension: 24px) {
  width: $dimension;
  height: $dimension;
  font-size: $dimension;
  line-height: $dimension;
}

@mixin button-icon-resize($dimension: 30px) {
  width: $dimension;
  height: $dimension;
  line-height: $dimension;
}

@mixin action-bar() {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: ftVars.$module-actions-height;
  max-height: ftVars.$module-actions-height;
  min-height: ftVars.$module-actions-height;

  .mat-mini-fab, .mat-raised-button, .mat-button, .mat-stroked-button {
     margin: 0 ftVars.$ft-small-spacer;
    height: 36px;
    // border-radius: ftVars.$ft-border-radius;
  }

  .mat-raised-button, .mat-button, .mat-stroked-button {
    font-weight: 500;
    // padding: 0 ftVars.$ft-medium-spacer;
    line-height: ftVars.$module-action-buttons-height;
    min-width: ftVars.$module-action-buttons-height;

    &.has-icon {
      padding: 0 ftVars.$ft-medium-spacer 0 ftVars.$ft-extra-small-spacer;

      .mat-button-wrapper .mat-icon {
        margin-right: ftVars.$ft-medium-spacer*0.5;
      }
    }
  }

  .mat-badge {
    z-index: 10;
  }

  .mat-mini-fab {
    width: 36px;

    .mat-button-ripple-round {
      border-radius: inherit;
    }
  }

  .mat-slide-toggle {
    padding: 0 ftVars.$ft-medium-spacer;

    .mat-slide-toggle-content {
      font-size: 15px;
    }
  }
}
