@use '../utils/variables' as ftVars;

// material overrides
@mixin apply-material-style() {
  a.mat-icon-button, a.mat-button, a.mat-stroked-button {
    display: flex;
    justify-content: center;
  }

  .mat-button-wrapper {
    display: flex;
    width: inherit;
    height: inherit;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  body .mat-mini-fab .mat-button-wrapper {
    padding: 0;
    display: flex;
    line-height: unset;
  }

  .ft-snackbar.mat-snack-bar-container {
    padding: 14px 18px;
    margin: ftVars.$ft-large-spacer;

    .mat-simple-snackbar {
      font-size: 15px;
    }
  }

  // mat list overrides
  .mat-list-base .mat-list-item {
    &.ft-list-item-disabled {
      cursor: default;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.5);
    }

    &.ft-list-item-active:not(.ft-list-item-disabled) {
      background-color: rgba(ftVars.$ft-primary-color, .1);

      .mat-list-text .mat-line, .mat-list-icon {
        color: ftVars.$ft-primary-color;
      }
    }
  }
}
